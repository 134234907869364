import {
  ADD_CARD,
  EDIT_CARD_DETAILS,
  EDIT_CARD_COORDINATES,
  CHANGE_LINE,
  CHANGE_BEAT,
  CHANGE_BOOK,
  REORDER_CARDS_WITHIN_LINE,
  REORDER_CARDS_IN_BEAT,
  DELETE_CARD,
  ATTACH_CHARACTER_TO_CARD,
  REMOVE_CHARACTER_FROM_CARD,
  ATTACH_PLACE_TO_CARD,
  REMOVE_PLACE_FROM_CARD,
  ATTACH_TAG_TO_CARD,
  REMOVE_TAG_FROM_CARD,
  ADD_CARD_IN_BEAT,
  LOAD_CARDS,
  LOAD_CARD,
  BATCH_LOAD_CARD,
  REMOVE_CARD,
  EDIT_CARD_TEMPLATE_ATTRIBUTE,
  ADD_TEMPLATE_TO_CARD,
  REMOVE_TEMPLATE_FROM_CARD,
  DUPLICATE_CARD,
  MOVE_CARD_TO_BOOK,
  EDIT_CARD_TITLE,
  EDIT_CARD_DESCRIPTION,
  EDIT_CARD_CUSTOM_ATTRIBUTE,
  REORDER_CARD_TEMPLATE_ATTRIBUTES,
} from '../constants/ActionTypes'

export function addCard(card, addMissingBeats) {
  return { type: ADD_CARD, card, addMissingBeats }
}

export function addNewCardInBeat(newCard, reorderIds) {
  return { type: ADD_CARD_IN_BEAT, newCard, reorderIds }
}

export function editCard(id, title, description, templates, attrs) {
  return { type: EDIT_CARD_DETAILS, id, attributes: { title, description, templates, ...attrs } }
}

export function editCardAttributes(id, attributes) {
  return {
    type: EDIT_CARD_DETAILS,
    id,
    attributes,
  }
}

export function editCardCustomAttribute(id, name, value, selection) {
  return {
    type: EDIT_CARD_CUSTOM_ATTRIBUTE,
    id,
    name,
    value,
    selection,
  }
}

export function editCardDescription(id, description, selection) {
  return {
    type: EDIT_CARD_DESCRIPTION,
    id,
    description,
    selection,
  }
}

export function editCardTitle(id, newTitle, selection) {
  return {
    type: EDIT_CARD_TITLE,
    id,
    newTitle,
    selection,
  }
}

export function editCardCoordinates(id, lineId, beatId, bookId) {
  return { type: EDIT_CARD_COORDINATES, id, lineId, beatId, bookId }
}

export function addTemplateToCard(id, templateData) {
  return { type: ADD_TEMPLATE_TO_CARD, id, templateData }
}

export function editCardTemplateAttribute(id, templateId, name, value, selection) {
  return {
    type: EDIT_CARD_TEMPLATE_ATTRIBUTE,
    id,
    templateId,
    name,
    value,
    selection,
  }
}

export function changeLine(id, lineId, bookId) {
  return { type: CHANGE_LINE, id, lineId, bookId }
}

export function changeBeat(id, beatId, bookId) {
  return { type: CHANGE_BEAT, id, beatId, bookId }
}

export function changeBook(id, bookId) {
  return { type: CHANGE_BOOK, id, bookId }
}

export function reorderCardsWithinLine(beatId, lineId, ids, addMissingBeats) {
  return { type: REORDER_CARDS_WITHIN_LINE, beatId, lineId, ids, addMissingBeats }
}

export function reorderCardsInBeat(
  beatId,
  lineId,
  newOrderInBeat,
  newOrderWithinLine,
  newIdInBeat,
  bookId
) {
  return {
    type: REORDER_CARDS_IN_BEAT,
    beatId,
    lineId,
    newOrderInBeat,
    newOrderWithinLine,
    newIdInBeat,
    bookId,
  }
}

export function deleteCard(id) {
  return { type: DELETE_CARD, id }
}

export function addCharacter(id, characterId) {
  return { type: ATTACH_CHARACTER_TO_CARD, id, characterId }
}

export function addPlace(id, placeId) {
  return { type: ATTACH_PLACE_TO_CARD, id, placeId }
}

export function addTag(id, tagId) {
  return { type: ATTACH_TAG_TO_CARD, id, tagId }
}

export function removeCharacter(id, characterId) {
  return { type: REMOVE_CHARACTER_FROM_CARD, id, characterId }
}

export function removePlace(id, placeId) {
  return { type: REMOVE_PLACE_FROM_CARD, id, placeId }
}

export function removeTag(id, tagId) {
  return { type: REMOVE_TAG_FROM_CARD, id, tagId }
}

export function removeTemplateFromCard(id, templateId) {
  return { type: REMOVE_TEMPLATE_FROM_CARD, id, templateId }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function load(patching, cards) {
  return { type: LOAD_CARDS, patching, cards }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function loadSingle(patching, card) {
  return { type: LOAD_CARD, patching, card }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function batchLoad(patching, cards) {
  return { type: BATCH_LOAD_CARD, patching, cards }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function removeSingle(patching, card) {
  return { type: REMOVE_CARD, patching, card }
}

export function duplicateCard(id) {
  return { type: DUPLICATE_CARD, id }
}

export function moveCardToBook(bookId, cardId) {
  // NOTE: we rely on the root reducer to compute the destination beat
  // and line ids.
  return { type: MOVE_CARD_TO_BOOK, bookId, cardId }
}

export const reorderCardTemplateAttribute = (originalPosition, destination, cardId) => {
  return {
    type: REORDER_CARD_TEMPLATE_ATTRIBUTES,
    originalPosition,
    destination,
    id: cardId,
  }
}
